/** @jsx jsx */
import { jsx, Box, Flex, Themed, useThemeUI } from 'theme-ui';
import { useMemo } from 'react';
import { ProductGalleryProps } from './types';
import { Container, CallToAction, ProductCard } from '@sprinklr/shared-lib';
import { getLinearGradient } from '@sprinklr/shared-lib/utils/getLinearGradient';

const adaptMobileCards = cards => {
  const defaultCards = [];
  const inverseCards = [];
  cards.forEach(card =>
    card.callToActions ? defaultCards.push(card) : inverseCards.push(card),
  );
  return [...defaultCards, ...inverseCards];
};

const ProductCards = ({ productCards, mobile }) => {
  const cards = useMemo(() => {
    return mobile ? adaptMobileCards(productCards) : productCards;
  }, [productCards, mobile]);

  const { theme } = useThemeUI();

  return (
    <Flex
      sx={{
        display: mobile ? ['flex', 'flex', 'none'] : ['none', 'none', 'flex'],
        flexWrap: 'wrap',
        justifyContent: ['center', null, null, 'center'],
      }}
    >
      {cards.map((card, index) => {
        const color = theme.rawColors[card?.product.shortName];
        return (
          <Box
            key={card?.product.name}
            sx={{
              mx: [0, '20px', '20px'],
              my: 3,
              order: mobile && index === 4 && 6,
            }}
          >
            <ProductCard
              {...card}
              cardSx={{
                borderRadius: ['10px'],
                height: ['100%'],
                width: ['335px', '375px', '500px', '35vw'],
                maxWidth: '550px',
              }}
            />
          </Box>
        );
      })}
    </Flex>
  );
};

const ProductGallery: React.FC<ProductGalleryProps> = ({
  sectionId,
  headline,
  callToActions,
  ...props
}) => {
  const ctas = callToActions;
  const productCards = props.productCards?.map(productCard => ({
    variant: productCard.variant,
    callToActions: productCard.callToActions,
    product: {
      ...productCard.product,
      description: productCard.product.description.description,
    },
  }));

  return (
    <Container id={sectionId} sx={{ maxWidth: '1430px' }}>
      <Flex sx={{ flexDirection: 'column', alignItems: 'center' }}>
        <Themed.h3 sx={{ textAlign: 'center', mx: [0, 6, 6], mb: [4, 5, 5] }}>
          {headline}
        </Themed.h3>
        <ProductCards productCards={productCards} mobile />
        <ProductCards productCards={productCards} mobile={false} />
        {ctas && (
          <Flex
            sx={{
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              pt: [1, 4, 5],
              pb: [4, 0, 0],
            }}
          >
            {ctas?.map((cta, index) => (
              <Box key={index} sx={{ marginLeft: index > 0 ? 4 : 0 }}>
                <CallToAction {...cta} />
              </Box>
            ))}
          </Flex>
        )}
      </Flex>
    </Container>
  );
};

export default ProductGallery;
